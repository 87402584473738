(function() {

  function checkOption(field, disableableFieldsList) {
    return () => {
      const disabledClass = 'feedback__item_disabled';

      const dataValue = field.options[field.selectedIndex].getAttribute('data-value');

      if (dataValue === "true") {
        disableableFieldsList.forEach(dField => {
          dField.classList.remove(disabledClass);
          dField.removeAttribute('disabled');
          dField.setAttribute('required', '')
        })
      } else {
        disableableFieldsList.forEach(dField => {
          dField.classList.add(disabledClass);
          dField.setAttribute('disabled', '');
          dField.removeAttribute('required')
        })
      }
    }
  }

  const participation = document.querySelector('#participation');
  const disableableFields = document.querySelectorAll('.feedback__item_disableable input,textarea');
  if (!participation) return;

  participation.addEventListener('change', checkOption(participation, disableableFields));
})();
