(function() {
  var gallery = document.querySelector(".gallery");
  if (gallery) {
    var url = window.location.href + "/pictures";
    var photos = gallery.querySelectorAll('.photo__content');
    var fullPhoto = gallery.querySelector('.gallery__full');
    var fullPhotoContent = fullPhoto.querySelector('.gallery__full-content');
    var close = fullPhoto.querySelector('.gallery__control_cross');
    var left = fullPhoto.querySelector('.gallery__control_left');
    var right = fullPhoto.querySelector('.gallery__control_right');

    var keyCodes = {
      ENTER: 13,
      ESC: 27,
      RIGHT: 39,
      LEFT: 37
    }

    var currentPhotoId = -1;
    var currentPhotoSrc = '';
    var albumPhotosData = [];

    function setClassVisible(element, isVisible) {
      element.classList.toggle('hidden', !isVisible);
    }

    function setPhoto(element, src) {
      element.querySelector('img').src = src;
    }

    function findPhotoById(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].id === currentPhotoId) {
          return data[i];
        }
      }
      console.error('photo not found');
    }

    function changePhoto(getIndex) {
      var currentIndex = albumPhotosData.indexOf(findPhotoById(albumPhotosData));
      if (currentIndex !== -1) {
        var index = getIndex(currentIndex);
        currentPhotoId = albumPhotosData[index].id;
        currentPhotoSrc = albumPhotosData[index].src;
        setPhoto(fullPhotoContent, currentPhotoSrc);
      }
    }

    function onCloseClick() {
      setClassVisible(fullPhoto, false);

      document.removeEventListener('keydown', onKeydownClick);
      document.removeEventListener('keydown', onRightKeydown);
      document.removeEventListener('keydown', onLeftKeydown);

      close.removeEventListener('click', onCloseClick);
      left.removeEventListener('click', onLeftClick);
      right.removeEventListener('click', onRightClick);
    }

    function onLeftClick() {
      changePhoto(function(currentIndex) {
        return currentIndex === 0 ? albumPhotosData.length - 1 : currentIndex - 1;
      });
    }

    function onRightClick() {
      changePhoto(function(currentIndex) {
        return currentIndex === albumPhotosData.length - 1 ? 0 : currentIndex + 1;
      });
    }

    function onKeydownClick(evt) {
      if (evt.keyCode === keyCodes.ENTER) {
        switch (evt.target) {
          case close:
            onCloseClick();
            break;
          case left:
            onLeftClick();
            break;
          case right:
            onRightClick();
            break;
        }
      } else if (evt.keyCode === keyCodes.ESC) {
        onCloseClick();
      }
    }

    function onRightKeydown(evt) {
      if (evt.keyCode === keyCodes.RIGHT) {
        onRightClick();
      }
    }

    function onLeftKeydown(evt) {
      if (evt.keyCode === keyCodes.LEFT) {
        onLeftClick();
      }
    }

    function onPhotoClick(evt) {
      currentPhotoId = parseInt(evt.target.dataset.key);
      var currentPhoto = findPhotoById(albumPhotosData);
      currentPhotoSrc = currentPhoto.src;

      setPhoto(fullPhotoContent, currentPhotoSrc);
      setClassVisible(fullPhoto, true);

      document.addEventListener('keydown', onKeydownClick);
      document.addEventListener('keydown', onRightKeydown);
      document.addEventListener('keydown', onLeftKeydown);

      close.addEventListener('click', onCloseClick);
      left.addEventListener('click', onLeftClick);
      right.addEventListener('click', onRightClick);
      fullPhoto.addEventListener('click', function (evt) {
        if (evt.target === fullPhoto || evt.target === fullPhotoContent) {
          onCloseClick();
        }
      });
    }

    function getMessage(status) {
      switch (status) {
        case 403:
          return 'Нет доступа к данным';
        case 404:
          return 'Не найдено';
        case 500:
          return 'Не отвечает сервер';
        default:
          return 'Произошла ошибка, обратитесь к администратору';
      }
    };

    function onLoad(photosData) {
      if (window.innerWidth >= 768) {
        albumPhotosData = photosData;
        Array.prototype.slice.call(photos).forEach(function(photo) {
          photo.addEventListener('click', onPhotoClick);
        });
      }
    }

    function getPhotosData(onLoadCallback) {
      var xhr = new XMLHttpRequest();
      xhr.responseType = 'json';
      xhr.open('GET', url);
      xhr.addEventListener('load', function () {
        if (xhr.status === 200) {
          onLoadCallback(xhr.response.pictures || []);
        } else {
          window.showErrorWindow(getMessage(xhr.status));
        }
      });
      xhr.send();
    }

    getPhotosData(onLoad);
  }
})();
