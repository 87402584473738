(function() {
  //slider
  var slider = document.querySelector('.slider');
  if (slider) {
    var sliderList = slider.querySelector('.slider__list');
    var sliderItems = slider.querySelectorAll('.slider__item');
    var prev = slider.querySelector('.slider__toggle_prev');
    var next = slider.querySelector('.slider__toggle_next');

    var ACTIVE_CLASS = 'slider__item_active';
    var SLIDER_TIMEOUT = 3000;
    var sliderTimeoutId = 0;

    function updateActive(currentIndex, index) {
      sliderItems[currentIndex].classList.remove(ACTIVE_CLASS);
      sliderItems[index].classList.add(ACTIVE_CLASS);
    }

    function getPrevIndex(currentIndex) {
      return currentIndex === 0 ? sliderItems.length - 1 : currentIndex - 1;
    }

    function getNextIndex(currentIndex) {
      return currentIndex === sliderItems.length - 1 ? 0 : currentIndex + 1;
    }

    function imageChange(callback) {
      var active = slider.querySelector('.slider__item_active');
      var currentIndex = parseInt(active.dataset.index);
      if (!isNaN(currentIndex)) {
        var index = callback(currentIndex);
        updateActive(currentIndex, index);
      }
      if (sliderTimeoutId) {
        clearInterval(sliderTimeoutId);
        sliderTimeoutId = setInterval(onNextClick, SLIDER_TIMEOUT);
      }
    }

    function onPrevClick() {
      imageChange(getPrevIndex);
    }

    function onNextClick() {
      imageChange(getNextIndex);
    }

    prev.addEventListener('click', onPrevClick);
    next.addEventListener('click', onNextClick);
    document.addEventListener('DOMContentLoaded', function() {
      sliderTimeoutId = setInterval(onNextClick, SLIDER_TIMEOUT);
    });
  }
})()
