(function() {
  var ACTIVE_CLASS = 'header__menu-btn_active';
  var CLOSE_CLASS = 'header__nav-list_close';
  var menu = document.querySelector('.header__menu');
  var hmButton = menu.querySelector('.header__menu-btn');
  var navList = document.querySelector('.header__nav-list');

  function onHamburgerClick() {
    hmButton.classList.toggle(ACTIVE_CLASS);
    navList.classList.toggle(CLOSE_CLASS);
  }

  menu.addEventListener('click', onHamburgerClick);
})();
